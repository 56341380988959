import React, { Component } from 'react';
import Modal from 'react-modal';

import RadioGroup from 'components/radioGroup/RadioGroup';

import FileIcon from 'icons/FileIcon';

import {
  isPaid
} from 'util/meetHelper';

import {
  exportAwardsResults,
  exportFederationResults
} from 'util/exportData';

import { exportOpenPowerliftingResults } from 'util/openPowerlifting';

class ExportResultsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: 'LIFTINGCAST'
    };
  }

  onRadioSelect = (value) => {
    this.setState({format: value});
  }

  export = () => {
    if (this.state.format === 'LIFTINGCAST') {
      exportAwardsResults(this.props.meet, this.props.data, this.props.isLocal);
    } else if (this.state.format === 'OPEN_POWERLIFTING') {
      exportOpenPowerliftingResults(this.props.meet, this.props.data);
    } else if (this.state.format === 'FEDERATION') {
      exportFederationResults(this.props.meet, this.props.data);
      !isPaid(this.props.meet) && this.props.openDonateModal();
    }

    this.props.onRequestClose();
  }


  render() {
    const { onRequestClose, isOpen, meet } = this.props;
    const exportOptions = [
      {label: 'LiftingCast Standard', value: 'LIFTINGCAST'},
      {label: 'Open Powerlifting', value: 'OPEN_POWERLIFTING'}
    ];

    if (meet.federation !== 'OTHER' && meet.federation !== 'WPP' && this.props.isLocal) {
      const federation = meet.federation || 'Federation';
      exportOptions.push({label: `${federation} Standard`, value: 'FEDERATION'});
    }

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel="Export Results"
        onRequestClose={onRequestClose}
        className={{
          base: 'export-results-modal',
          afterOpen: 'export-results-modal-after-open',
          beforeClose: 'export-results-modal-before-close'
        }}
        overlayClassName={{
          base: 'export-results-modal-overlay',
          afterOpen: 'export-results-modal-overlay-after-open',
          beforeClose: 'export-results-modal-overlay-before-close'
        }}
      >
        <div className="content">
          <h1>Select Format</h1>
          <RadioGroup
            onCheck={this.onRadioSelect}
            value={this.state.format}
            items={exportOptions}
          />
        </div>
        <div className="button-row">
          <button onClick={onRequestClose} style={{marginRight: 12}}>Cancel</button>
          <button onClick={this.export}><FileIcon /> &nbsp; Export</button>
        </div>
      </Modal>
    );
  }
}

export default ExportResultsModal;
